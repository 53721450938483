


























































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { UserModule } from "@/store/modules/user";
import { instrumentApi } from "@/api/instrument";
import { orderApi } from "@/api/order";
import {IUserParams, userApi} from "@/api/user";
import { enterpriseApi } from "@/api/enterprise";
import PERMISSION from "@/constants/permission";
import { processApis } from "@/api/process";
import GenerateForm from "@/components/VueFormMaking/components/GenerateForm.vue";
import moment from "moment";
import { log } from 'util'

@Component({ components: { GenerateForm } })
export default class Order extends Vue {
  @Prop() instrumentId: any;
  @Prop() queryQ: any;
  @Prop() processName: any;
  @Prop() type: any;
  @Prop() state: any;
  @Prop() finish: any;
  @Ref("baofei-table") baoFeiTable!: any
  @Prop({default:true}) needType: boolean|undefined
  params = { page: 1, perPage: 15 };
  orders: any = {};
  loading = false;
  instruments: any = {};
  createModifyLoad = false;
  title = "新增仪器组";
  openType = "create";
  groupFields = [];
  openLoading = false;
  currenOrder: any = {};
  departments = {};
  enterprises = {};
  users = {};
  lastData: any = {};
  againOrderVisible = false;
  scrapVisible = false;
  scrapLoading = false
  scrapList = [];
  scrapData = [];
  processList: any = [];
  condition: any = {};
  orderDetailVisible = false;
  formValues = {
    comment: "",
  };
  actionLoading: boolean = false;
  rules = {
    comment: [{ required: false, message: "请输入备注", trigger: "blur" }],
  };

  ysShowBtn = {} as {[key: number]: boolean}

  scrapInstruments = []

  selectable(row: any, _: any) {
    return row.status !== 13
  }

  get config() {
    return UserModule.config;
  }

  get formatterLabel() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return "";
      }
      const statusLabel = this.insStatusOptions?.find(
        // @ts-ignore
        ({ key }) => key === row.status
      )?.label;
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return `停用(${statusLabel})`;
      }
      // @ts-ignore
      return `${this.config?.in_tentative?.[row.inTentative]}(${statusLabel}）`;
    };
  }

  get insStatusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }

  get statusIcon() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return -2;
      }
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return -1;
      }
      if (row.inTentative) return 1;
      return 0;
    };
  }

  handleSelectionChange(rows: any) {
    this.scrapInstruments = rows
  }

  async scrapInstrument() {
    this.scrapLoading = true
    const ids = [] as any[]
    for (const ins of this.scrapInstruments) {
      // @ts-ignore
      ids.push(ins.id)
    }
    instrumentApi.scrapInstrument(ids).then(() => {
      this.$message.success("报废成功")
      this.scrapVisible = false;
    }).catch((e) => {
      this.$message.warning(`报废失败, ${e}`)
    }).finally(() => {
      this.scrapLoading = false
    })
  }
  async initYsShowBtn() {
    for (const order of this.orders.items) {
      if (this.ysShowBtn[order.id]) {
        continue
      }
      if (order.process_data.name !== "验收") {
        continue
      }
      const currNode = order.current_nodes
      let taskDetail = {} as any
      for (const key in currNode) {
        if (currNode[key].hasOwnProperty('assignType')) {
          taskDetail = currNode[key]
          break
        }
      }
      const approvalUsers = []
      if (taskDetail.assignType === 'person') {
        approvalUsers.push(...taskDetail.assignValue)
      } else if (taskDetail.assignType === 'role') {
        for (const roleId of taskDetail.assignValue) {
          const users = await userApi.getUsers({ roleId: roleId } as IUserParams)
          for (const user of users.items) {
            approvalUsers.push(user.id)
          }
        }
      }
      this.ysShowBtn[order.id] = approvalUsers.indexOf(UserModule.user.id) !== -1
    }
  }

  showRevoke(order: any) {
    return order.create_id === UserModule.user.id
  }

  get showCheck() {
    // @ts-ignore
    return (row, type) => {
      if (this.currenOrder?.process_data?.name === "验收" && type !== "check") {
        return this.ysShowBtn[this.currenOrder.id]
      }
      let nodesIds = row?.related_persons?.apply_persons?.filter(
        // @ts-ignore
        (id) =>
          !Object.keys(row?.current_nodes)
            ?.reduce(
              (s: any, c: any) => [
                ...new Set([
                  ...s,
                  ...(row?.related_persons?.approval_persons[c] || []),
                ]),
              ],
              []
            )
            .includes(id)
      ) || [];

      if (row?.current_nodes) {
        nodesIds = [...nodesIds, ...Object.keys(row?.current_nodes)
          ?.reduce(
            (s: any, c: any) => [
              ...new Set([
                ...s,
                ...(row?.related_persons?.approval_persons[c] || []),
              ]),
            ],
            []
          )]
      }

      if (row.state !== 0) return false;
      if (type === "check") {
        return (
          nodesIds.includes(UserModule.user.id) ||
          row.create_id === UserModule.user.id
        );
      }
      return nodesIds.includes(UserModule.user.id);
    };
  }
  get againForm() {
    const { formStructure = {}, processStructure = {} } = this.lastData;
    const { list = [], config = [] } = formStructure;
    const startNode = processStructure?.nodes?.find(
      // @ts-ignore
      (node) => node.clazz === "start"
    );
    return {
      config,
      // @ts-ignore
      list: list.filter((item) =>
        startNode?.visiableFields?.split(",")?.includes(item.model)
      ),
    };
  }

  get showLeft() {
    const path = this.$route.path;
    if (path === "/ins/order") return true;
    return false;
  }
  get formatterIns() {
    // @ts-ignore
    return (row) =>
      row.instrument_ids
        ?.map(
          // @ts-ignore
          (id) => this.instruments?.items?.find((ins) => ins.id === id)?.name
        )
        .join("、") || "/";
  }
  get againFormData() {
    const { form } = this.lastData;
    return form;
  }
  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }
  get PERMISSION() {
    return PERMISSION;
  }
  get currentHistory() {
    const { edges = [], nodes = [] } =
      this.currenOrder?.process_data?.structure || {};
    const { config, list } = this.currenOrder?.form_data?.form_structure || {};
    const { history } = this.currenOrder || {};
    const times = edges
      .reduce((s: any, c: any) => {
        if (s?.includes(c.target)) return [...s];
        if (s?.length) {
          return [...s, c.target];
        }
        return [c.source, c.target];
      }, [])
      ?.map((id: string) => nodes?.find((child: any) => child.id === id))
      ?.filter((child: any) => child.label !== "结束");
    return times?.map((child: any) => {
      return {
        ...child,
        color: !!history?.find((his: any) => his.source === child.id)
          ? "green"
          : !!history?.find((his: any) => his.target === child.id)
          ? "blue"
          : "grey",
        remarks: history?.find((his: any) => his.target === child.id)?.remarks,
        remarksShow: [4, 1, 5].includes(
          history?.find((his: any) => his.target === child.id)?.state
        ),
        form: {
          config,
          list: list
            .filter((item: any) =>
              child?.requiredFields?.split(",")?.includes(item.model)
            )
            ?.map((child: any) => ({
              ...child,
              options: { ...child.options, disabled: true },
            })),
        },
      };
    });
  }
  get currentForm() {
    const currentFields = Object.keys(this.currenOrder.current_nodes)
      .map((key: any) => this.currenOrder.current_nodes[key])
      ?.reduce(
        (s: any[], c: any) => [
          ...s,
          ...(c?.visiable_fields?.split(",").filter((field: any) => field) ||
            []),
        ],
        []
      );
    const requiredFields = Object.keys(this.currenOrder.current_nodes)
      .map((key: any) => this.currenOrder.current_nodes[key])
      ?.reduce(
        (s: any[], c: any) => [
          ...s,
          ...(c?.required_fields?.split(",").filter((field: any) => field) ||
            []),
        ],
        []
      );
    const { config, list } = this.currenOrder.form_data.form_structure;
    return {
      config,
      // list: list.map((item: any) => {
      //   if (currentFields.includes(item.model)) {
      //     return item;
      //   } else {
      //     const _item = JSON.parse(JSON.stringify(item));
      //     _item.options.disabled = true;
      //     return _item;
      //   }
      // }),
      list: list
        .filter((item: any) => currentFields.includes(item.model))
        .map((item: any) => {
          if (!requiredFields.includes(item.model)) {
            return {
              ...item,
              options: { ...item.options, disabled: true },
            };
          }
          return item;
        }),
    };
  }
  get formatterNode() {
    // @ts-ignore
    return (row) => {
      if ([-1, 1, -2].includes(row?.state)) return "/";
      const nodes = row?.process_data?.structure?.nodes;
      let currentNodes = []
      if (row?.current_nodes) {
        currentNodes = Object.keys(row?.current_nodes).map(
          (key) =>
            // @ts-ignore
            nodes?.find((child) => child.id === row?.current_nodes?.[key]?.id)
              ?.label
        );
      }
      return currentNodes?.join("、") || "/";
    };
  }
  get formatterUser() {
    // @ts-ignore
    return (row) => {
      let nodesIds = row?.related_persons?.apply_persons?.filter(
        // @ts-ignore
        (id) =>
          !Object.keys(row?.current_nodes)
            ?.reduce(
              (s: any, c: any) => [
                ...new Set([
                  ...s,
                  ...(row?.related_persons?.approval_persons[c] || []),
                ]),
              ],
              []
            )
            .includes(id)
      );

      if (row?.current_nodes) {
        nodesIds = [...nodesIds, ...Object.keys(row?.current_nodes)
          ?.reduce(
            (s: any, c: any) => [
              ...new Set([
                ...s,
                ...(row?.related_persons?.approval_persons[c] || []),
              ]),
            ],
            []
          )]
      }

      const names = []
      for (const nodeId of nodesIds) {
        // @ts-ignore
        const username = this.users?.items?.find((item) => item.id === nodeId)?.name
        if (!username) {
          continue
        }
        names.push(username)
      }
      const data = []
      for (const name of names) {
        if (data.indexOf(name) !== -1) {
          continue
        }
        data.push(name)
      }

      return data
    };
  }

  STATE_MAP = {
    AGREE: 1,
    REFUSE: 4,
    REPEAL: 5,
  };
  STATE_LABEL_MAP = {
    [this.STATE_MAP.AGREE]: "同意",
    [this.STATE_MAP.REFUSE]: "拒绝",
    [this.STATE_MAP.REPEAL]: "撤销",
  };
  orderVisible: boolean = false;
  orderCheckVisible = false;
  statusOptions = [
    {
      value: -1,
      label: "撤销",
    },
    {
      value: 0,
      label: "审批中",
    },
    {
      value: 1,
      label: "通过",
    },
    {
      value: -2,
      label: "拒绝",
    },
    {
      value: -3,
      label: "关闭",
    },
    {
      value: -4,
      label: "终止",
    },
  ];
  typeOptions = [
    {
      value: "验收",
      label: "验收",
    },
    {
      value: "调整",
      label: "调整",
    },
    {
      value: "维修",
      label: "维修",
    },
    {
      value: "处置",
      label: "处置",
    },
    {
      value: "检定校准",
      label: "检定校准",
    },
    {
      value: "期间核查",
      label: "期间核查",
    },
    {
      value: "新增",
      label: "新增",
    },
  ];
  get extraConfig() {
    const config:any = [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        // @ts-ignore
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
      {
        key: "createDateRange",
        label: "起止时间",
        type: "datetimerange",
        placeholder: "请选择开始结束时间",
      },
      {
        key: "state",
        label: "状态",
        type: "select",
        options: this.statusOptions,
        placeholder: "请选择状态",
        hidden: this.state,
      },
      {
        key: "myOrder",
        label: "我创建的工单",
        type: "eitherOr",
        value: 1,
      },
      {
        key: "orderType",
        label: "待我审批",
        type: "eitherOr",
        value: "todo",
      },
    ];
    if(this.needType) {
      config.splice(2,0,{
        key: "q",
        label: "类型",
        type: "select",
        options: this.typeOptions,
        placeholder: "请选择类型",
      })
    }
    return config
  }
  get formatterState() {
    return (state: number) =>
      this.statusOptions?.find((child) => child.value === state)?.label;
  }
  getInstruments() {
    instrumentApi
      // @ts-ignore
      .getInstruments({ page: 1, perPage: 99999 })
      .then((res) => (this.instruments = res));
  }
  onRefresh(condition: any) {
    this.condition = condition;
    this.getOrders();
  }
  async onAction(type: number) {
    if (this.currenOrder?.process_data?.name === "验收" && type !== this.STATE_MAP.REPEAL) {
      const form = this.currenOrder.form_data.form_data.ins_form
      if (Object.keys(form).map(key => form[key]).find(item => !item.is_save)) {
        this.$message.warning("没有全部确认, 请检查表单");
        return
      }
    }
    const getComment = () => {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        this.$refs.commentRef.validate((valid: boolean) => {
          if (valid) resolve(true);
          reject(false);
        });
      });
    };
    this.actionLoading = true;
    try {
      const [{ value: commentRef }, { value: currentFormData }]: any = await (
        Promise as any
        // @ts-ignore
      ).allSettled([getComment(), this.$refs.generateDetailForm.getData()]);
      if (commentRef && currentFormData) {
        const formData = {
          ...this.currenOrder.form_data.form_data,
          ...currentFormData,
        };
        // 先保存
        // await orderApi.modifyOrder({
        //   formData,
        //   status: 7,
        //   id: this.currenOrder?.id,
        // });
        // 再审批
        // @ts-ignore
        const { second, formStructure, processStructure, processId, form } =
          await orderApi.modifyOrder({
            formData,
            comment: this.formValues.comment,
            status: type,
            id: this.currenOrder.id,
          });
        if (second) {
          if (this.currenOrder?.title?.indexOf("检定") > -1) {
            return this.$router.push("/fix");
          }
          if (this.currenOrder?.title?.indexOf("验收") > -1) {
            return this.$router.push("/verity");
          }
          this.onOpenOrder({
            second,
            formStructure,
            processStructure,
            processId,
            form,
          });
          // await orderApi.createOrder({
          //   processId,
          //   formData: form,
          // });
        } else {
          this.getOrders();
        }
        this.$message.success(`${this.STATE_LABEL_MAP[type]}成功`);
        this.orderCheckVisible = false;
      }
    } catch (error) {
      this.$message.error(error || `${this.STATE_LABEL_MAP[type]}失败`);
    }
    this.actionLoading = false;
  }
  // 重新发起流程
  async onOpenOrder(lastData: any) {
    this.lastData = lastData;
    setTimeout(() => {
      this.againOrderVisible = true;
    }, 500);
  }
  get getCheckDialogWidth() {
    if (this.currenOrder?.process_data?.name == "验收") {
      if(this.orderDetailVisible) return '1200px'
      return "1100px"
    } else if (this.currenOrder?.process_data?.name == "调整") {
      if(this.orderDetailVisible) return '1200px'
      return "1100px"
    } else if (this.currenOrder?.process_data?.name == "新增") {
      if(this.orderDetailVisible) return '1100px'
      return "1100px"
    }
    return "600px"
  }
  async onOpen(row: any, type = "view") {
    this.currenOrder = row;
    if (type === "edit") {
      this.orderVisible = true;
    } else if (type === "check") {
      this.orderCheckVisible = true;
    } else if (type === "scrap") {
      this.scrapData = []
      for (const ins_id of row.form_data.form_data.instrument) {
        const ins = await instrumentApi.getInstrumentDetail(ins_id);
        const child_ins = await instrumentApi.getChildInstrument(ins_id);
        if (child_ins) {
          let _childIns = [];
          // @ts-ignore
          if (child_ins.peijian.length > 0) {
            // @ts-ignore
            for (const peijian of child_ins.peijian) {
              _childIns.push(peijian);
            }
          };
          if (_childIns.length > 0) {
            // @ts-ignore
            ins.children = _childIns;
          }
          console.info(_childIns);

        }
        // @ts-ignore
        this.scrapData.push(ins);
        // @ts-ignore
        this.scrapList.push(ins_id);
      }
      // this.scrapList.push(row.form_data.form_data.instrument);
      // this.scrapData = []
      // console.info(this.scrapList);
      this.scrapVisible = true;
    } else {
      this.orderDetailVisible = true;
    }
  }
  async scrapIns(row: any) {
    try {
      console.info(111)
      console.info(row.form_data.form_data.instrument)
    } catch (error) {
      this.$message.error(error);
    }
  }
  //
  async doCreateOrder() {
    try {
      // @ts-ignore
      const formData = await this.$refs.againGenerateForm.getData();
      await orderApi.createOrder({
        processId: this.lastData?.processId,
        formData,
      });
      this.againOrderVisible = false;
      this.getOrders();
      this.$message.success("发起成功");
    } catch (error) {
      this.$message.error(error);
    }
  }

  getUsers() {
    const params = { page: 1, perPage: 99999 } as IUserParams;
    if (!this.isSuperAdmin) {
      // @ts-ignore
      params.enterpriseId = UserModule?.user?.enterprise?.id;
    }
    userApi
      .getUsers(params)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室失败, ${e}`);
      })
      .finally(() => {});
  }
  private async getOrders() {
    this.condition.createDateRange = this.condition.createDateRange
      // @ts-ignore
      ?.map((date) => moment(date).valueOf())
      .join(",");
    this.loading = true;
    try {
      const res = await orderApi.getOrders({
        q: this.queryQ || undefined,
        state: this.state || undefined,
        processName: this.processName || undefined,
        ...this.params,
        ...this.condition,
        instrumentId: this.instrumentId,
      });
      this.orders = res;
      await this.initYsShowBtn()
    } catch (error) {}
    this.loading = false;
  }
  // @ts-ignore
  private async getGroupDetail(id) {
    return await orderApi.getOrderDetail(id);
  }

  getProcessList() {
    processApis
      .search({ page: 1, perPage: 15 } as any)
      .then((r) => {
        this.processList = r?.items;
      })
      .catch((e) => {})
      .finally(() => {});
  }

  private getEnterprises(query: string) {
    enterpriseApi
      .getEnterprises({
        name: query,
        // @ts-ignore
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      });
  }

  private async doAction() {
    try {
      // @ts-ignore
      const formData = await this.$refs.generateForm.getData();
      await orderApi.modifyOrder({
        formData,
        // comment:'',
        status: 7,
        id: this.currenOrder?.id,
      });
      this.orderVisible = false;
      this.$message.success("编辑成功");
      this.getOrders();
    } catch (error) {
      this.$message.error(error);
    }
  }

  private created() {
    this.getEnterprises("");
    // this.getOrders();
    this.getUsers();
    this.getInstruments();
    // this.getProcessList();
  }
  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getOrders();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getOrders();
  }
}

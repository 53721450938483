

































































import { Component, Vue, Prop } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { departmentApi } from "@/api/department";
import { enterpriseApi, IGetEnterprisesParam } from "@/api/enterprise";
import { IUserParams, userApi } from "@/api/user";
import { groupApi, IGroupParams } from '@/api/group'
import { instrumentApi } from '@/api/instrument'
import PERMISSION from "@/constants/permission";
import InstrumentCreateForm from '@/components/InstrumentCreateForm.vue'

@Component({components: {InstrumentCreateForm}})
export default class Record extends Vue {
  @Prop() data: any;
  @Prop() fields: any;
  @Prop({default: true}) editable!: boolean;
  @Prop({default: false}) linkTo!: boolean;
  users: any = [];
  groups: any = [];
  enterprises: any = [];
  departments: any = [];
  private openType = "create";
  private visible = false;
  private openLoading = false;
  private createModifyParams: any = {
    enterpriseId: 1,
    departmentId: null,
    name: "",
    code: "",
    extraFields: {},
  };
  createModifyLoad = false
  private rules = {
    name: [{ required: true, message: "请输入名称", trigger: "blur" }],
    code: [{ required: true, message: "请输入设备编号", trigger: "blur" }],
    departmentId: [{ required: true, message: "请选择", trigger: "blur" }],
  };
  instrumentFields = []

  private needApprovalOptions: any = {
    0: "不需要",
    1: "需要",
  };
  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }
  get PERMISSION() {
    return PERMISSION;
  }
  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }
  get visibleColumns() {
    // @ts-ignore
    return UserModule.user.visible_columns;
  }
  get showField() {
    const { user, isSuperAdmin }: any = UserModule;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    return (key: string) => {
      return this.visibleColumns.includes(key) || isAdmin || isSuperAdmin;
    };
  }

  get config() {
    return UserModule.config;
  }
  get formatterLabel() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return "";
      }
      const statusLabel = this.statusOptions?.find(
        // @ts-ignore
        ({ key }) => key === row.status
      )?.label;
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return `停用（${statusLabel}）`;
      }
      // @ts-ignore
      return `${this.config?.in_tentative?.[row.inTentative]}(${statusLabel}）`;
    };
  }
  handleChangeTime(_: any) {
    //
  }
  get statusIcon() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return -2;
      }
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return -1;
      }
      if (row.inTentative) return 1;
      return 0;
    };
  }

  get allFields() {
    let res = []
    for (const items of this.formatterList) {
      res.push(...items)
    }
    return res
  }

  get formatterList() {
    const spanFields = this.fields
      .filter((field: any) => this.showField(String(field.key)))
      .map((field: any) => {
        let span = 8;
        if (["datetime"].includes(field.type)) {
          span = 16;
        }
        if (
          [
            "name",
            "entry_date",
            "buy_date",
            "next_inspect_date",
            "next_check_date",
            "next_maintain_date",
          ].includes(field.key)
        ) {
          span = 16;
        }
        const showKey = String(field?.key)?.replace(
          /\_(\w)/g,
          function (all: any, letter: string) {
            return letter.toUpperCase();
          }
        );
        return { ...field, span, showKey };
      });
    let showList: any[] = [];
    spanFields.forEach((field: any) => {
      if (showList?.length) {
        const [last] = [...showList].reverse();
        const lastSpan = last.reduce((s: number, c: any) => s + c.span, 0);
        if (lastSpan === 24) {
          return showList.push([field]);
        }
        if (field.span + lastSpan <= 24) {
          return showList[showList.length - 1].push(field);
        }
        const lastLastSpan =
          showList[showList.length - 1][last.length - 1]?.span;
        showList[showList.length - 1][last.length - 1].span =
          24 - (lastSpan - lastLastSpan);
        showList.push([field]);
      } else {
        showList.push([field]);
      }
    });
    console.log(showList)
    return showList;
  }
  get formatterContent() {
    return (key: string, type: string) => {
      let value = this.data?.[key];
      if (key === "status") {
        value = this.formatterLabel(this.data);
      }
      if (key === "needApproval") {
        value = this.needApprovalOptions[this.data?.[key] as number];
      }
      if (key === "custodian") {
        value = this.users?.items?.find(
          (user: any) => user.id === this.data?.custodianId
        )?.name;
      }
      if (key === "departmentId") {
        value = this.departments?.items?.find(
          (department: any) => department.id === this.data?.departmentId
        )?.name;
      }
      if (key === "deviceGroupId") {
        value = this.groups?.items?.find(
          (group: any) => group.id === this.data?.deviceGroupId
        )?.name;
      }
      if (key === "enterpriseId") {
        value = this.enterprises?.items?.find(
          (enterprise: any) => enterprise.id === this.data?.enterpriseId
        )?.name;
      }
      if (type) {
        value = this.data.extra_fields?.[key];
      }
      return value || "/";
    };
  }

  private getGroups() {
    groupApi
      .getGroups({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGroupParams)
      .then((res) => {
        this.groups = res;
      });
  }

  private getDepartments() {
    departmentApi
      .getDepartments({
        name: "",
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {});
  }

  private getEnterprises() {
    enterpriseApi
      .getEnterprises({
        name: "",
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {});
  }

  private getUsers() {
    userApi
      .getUsers({
        page: 1,
        perPage: 999999,
        name: "",
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {});
  }
  async created() {
    this.getUsers();
    this.getGroups();
    this.getDepartments();
    this.getEnterprises();
    await this.getFields()
  }

  gotoDetail() {
    window.open(window.location.origin + `/#/ins/detail/${this.data.id}`,"_blank")
  }

  async onOpen(row: any = {}, type = "create") {
    if (type === "preview") {
      return this.$router.push(`/ins/detail/${row.id}`);
    }
    this.openType = type;
    this.openLoading = true;
    try {
      const data = await instrumentApi.getInstrumentDetail(this.$route.params.id);
      this.createModifyParams = {
        ...data,
        // need_approval: data.needApproval,
        // @ts-ignore
        extraFields: data.extra_fields,
        // @ts-ignore
        deviceGroupId: data.deviceGroupId || null,
      };
    } catch (error) {}
    this.openLoading = false;
    this.visible = true;
  }
  private doAction() {
    this.createModifyLoad = true;
    this.createModifyParams.id = this.$route.params.id
    instrumentApi.modifyInstrument(this.createModifyParams)
      .then(() => {
        this.$message.success("修改成功");
        this.visible = false;
        this.createModifyLoad = false;
        this.$emit("update")
      })
      .catch((e) => {
        this.$message.warning(`修改失败, ${e}`);
      })
      .finally(() => {
        this.createModifyLoad = false;
      });
  }
  private async getFields() {
    const [
      { value: sortRes = [] },
      { value: res = [] },
      { value: extraFields = [] },
    ]: any = await Promise.allSettled([
      enterpriseApi.getFieldsSort({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      }),
      enterpriseApi.getFields({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      }),
      instrumentApi.getInstrumentFields(),
    ]);

    const fields: any = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) =>
            !(sortRes?.length ? sortRes : [])?.find(
              (item: any) => child.key === item.key
            )
        ),
    ];
    this.instrumentFields = fields
      ?.filter(({ key }: any) => key !== "extend_info")
      .map((child: any) => {
        const field = extraFields.find((item: any) => item.id == child.key);
        if (field) {
          return {
            ...child,
            ...field,
          };
        }
        return child;
      });
  }
}
